<template>
  <div class="product-list">
    <div class="table-list-layout">
      <div class="table-list">
        <div class="header">
          <Breadcrumb>
            <BreadcrumbItem>{{ $t('chan-pin-ji-qun-guan-li') }}</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div class="content">
          <div class="option">
            <div class="left"></div>
            <div class="right">
              <Button ghost type="primary" @click="handleShowAddProductModal" icon="md-add"
                      style="margin-right: 10px;">{{ $t('tian-jia-chan-pin-ji-qun') }}
              </Button>
              <Button class="refresh" @click="getProductList" :loading="loading">{{
                  $t('shua-xin')
                }}
              </Button>
            </div>
          </div>
          <div class="table">
            <Table :columns="columns" :data="productClusterList" border
                   :locale="{emptyText: $t('zan-wu-shu-ju')}" :loading="loading"
                   size="small">
              <template slot="clusterCode" slot-scope="{ row }">
                <span
                  :class="`dot-span ${row.status === 'ONLINE'?'online-span':'offline-span'}`"></span>
                {{ row.clusterCode }}
              </template>
              <template slot="clusterDesc" slot-scope="{ row }">
                {{ row.clusterDesc }}
              </template>
              <template slot="productVersion" slot-scope="{ row }">
                {{ row.productVersion }}
                <cc-iconfont :size="8" name="edit"
                             style="margin-right: 4px;margin-left: 4px;"
                             @click.native="handleShowEditProductVersionModal(row)"/>
              </template>
              <template slot="apiAddr" slot-scope="{ row }">
                {{ row.apiAddr }}
                <cc-iconfont :size="8" name="edit"
                             style="margin-right: 4px;margin-left: 4px;"
                             @click.native="handleEditApiAddr(row)"/>
              </template>
              <template slot="actions" slot-scope="{ row }">
                <Poptip
                  transfer
                  confirm
                  :title="$t('que-ren-yao-shan-chu-gai-chan-pin-ji-qun-ma')"
                  :ok-text="$t('que-ding')"
                  :ok-cancel="$t('qu-xiao')"
                  @on-ok="handleDeleteProduct(row)"
                >
                  <Button size="small" type="text">
                    {{ $t('shan-chu') }}
                  </Button>
                </Poptip>
              </template>
            </Table>
          </div>
        </div>
      </div>
    </div>
    <Modal :title="$t('tian-jia-chan-pin-ji-qun')" v-model="showAddProductModal" :closable="false"
           @on-cancel="handleCloseModal">
      <Form
        ref="productClusterForm"
        :model="newProductForm"
        :rules="newProductClusterRules"
        label-position="right"
        :label-width="100"
      >
        <FormItem :label="$t('chan-pin-lei-xing')" prop="product">
          <Select v-model="newProductForm.product" style="width: 380px">
            <Option v-for="type in productTypes" :key="type" :value="type">{{ type }}</Option>
          </Select>
        </FormItem>
        <FormItem :label="$t('chan-pin-ji-qun-id')" prop="clusterCode">
          <Input
            style="width: 380px"
            type="text"
            v-model="newProductForm.clusterCode"
            :placeholder="$t('qing-shu-ru-chan-pin-ji-qun-wei-yi-id')"
            autocomplete="new-password"
            key="email"
          />
        </FormItem>
        <FormItem :label="$t('chan-pin-ji-qun-ming-cheng')" prop="clusterDesc">
          <Input
            style="width: 380px"
            type="text"
            v-model="newProductForm.clusterDesc"
            :placeholder="$t('qing-shu-ru-chan-pin-ji-qun-ming-cheng')"
            autocomplete="new-password"
            key="email"
          />
        </FormItem>
        <FormItem :label="$t('ban-ben-hao')" prop="productVersion">
          <Input
            style="width: 380px"
            type="text"
            v-model="newProductForm.productVersion"
            :placeholder="$t('qing-shu-ru-chan-pin-ji-qun-ban-ben-hao')"
            autocomplete="new-password"
            key="email"
          />
        </FormItem>
        <FormItem :label="$t('api-di-zhi')" prop="apiAddr">
          <Input
            style="width: 380px"
            type="text"
            v-model="newProductForm.apiAddr"
            :placeholder="$t('qing-shu-ru-chan-pin-ji-qun-tiao-yong-api-di-zhi')"
            autocomplete="new-password"
            key="email"
          />
        </FormItem>
      </Form>
      <div slot="footer">
        <Button @click="handleCloseModal">{{ $t('qu-xiao') }}</Button>
        <Button @click="handleAddProduct" type="primary">{{ $t('que-ding') }}</Button>
      </div>
    </Modal>
    <Modal v-model="showEditProductVersionModal" :title="$t('xiu-gai-ban-ben-hao')" :closable="false" :width="400">
      <Input v-model="currentProductVersion" type="textarea"/>
      <div slot="footer">
        <Button @click="handleCloseModal">{{ $t('qu-xiao') }}</Button>
        <Button @click="handleUpdateProductVersion" type="primary">{{ $t('que-ding') }}</Button>
      </div>
    </Modal>
    <Modal v-model="showEditApiAddrModal" :title="$t('xiu-gai-api-tiao-yong-di-zhi')" :closable="false"
             :width="400">
      <Input v-model="currentApiAddr" type="textarea"/>
      <div slot="footer">
        <Button @click="handleCloseModal">{{ $t('qu-xiao') }}</Button>
        <Button @click="handleUpdateApiAddr" type="primary">{{ $t('que-ding') }}</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
import {
  listProductCluster,
  listProductTypes,
  deleteProductCluster,
  updateProductVersion,
  updateApiAddr,
  addProductCluster
} from '@/services/cc/api/productCluster';
import deepClone from 'lodash.clonedeep';
import { mapActions, mapState } from 'vuex';

const EMPTY_PRODUCT = {
  product: 'CloudCanal',
  productVersion: '',
  clusterDesc: '',
  clusterCode: '',
  apiAddr: ''
};
export default {
  name: 'ProductList',
  data() {
    return {
      loading: false,
      showAddProductModal: false,
      showEditProductVersionModal: false,
      showEditApiAddrModal: false,
      selectedProductCluster: {},
      currentProductVersion: '',
      currentApiAddr: '',
      columns: [
        {
          title: this.$t('chan-pin-ji-qun-id'),
          slot: 'clusterCode'
        },
        {
          title: this.$t('chan-pin-ji-qun-ming-cheng'),
          slot: 'clusterDesc'
        },
        {
          title: this.$t('chan-pin-lei-xing'),
          key: 'product'
        },
        {
          title: this.$t('ban-ben-hao'),
          slot: 'productVersion'
        },
        {
          title: this.$t('api-tiao-yong-di-zhi'),
          slot: 'apiAddr'
        },
        {
          title: this.$t('cao-zuo'),
          slot: 'actions'
        }
      ],
      productTypes: [],
      newProductForm: deepClone(EMPTY_PRODUCT),
      newProductClusterRules: {
        product: [{
          required: true,
          trigger: 'blur',
          message: this.$t('chan-pin-lei-xing-bu-neng-wei-kong')
        }],
        productVersion: [{
          required: true,
          trigger: 'blur',
          message: this.$t('ban-ben-hao-bu-neng-wei-kong')
        }],
        clusterDesc: [{
          required: true,
          trigger: 'blur',
          message: this.$t('chan-pin-ming-cheng-bu-neng-wei-kong')
        }],
        clusterCode: [{
          required: true,
          trigger: 'blur',
          message: this.$t('chan-pin-ji-qun-id-bu-neng-wei-kong')
        }],
        apiAddr: [{
          required: true,
          trigger: 'blur',
          message: this.$t('api-di-zhi-bu-neng-wei-kong')
        }]
      }
    };
  },
  computed: {
    ...mapState(['myAuth', 'productClusterList'])
  },
  mounted() {
    this.listProductTypes();
  },
  methods: {
    ...mapActions(['getProductList']),
    handleAddProduct() {
      this.$refs.productClusterForm.validate(async (valid) => {
        if (valid) {
          addProductCluster({
            ...this.newProductForm
          }).then((res) => {
            this.handleCloseModal();
            if (res.data.success) {
              this.$Message.success(this.$t('tian-jia-cheng-gong'));
              window.location.reload();
            }
          });
        }
      });
    },
    handleShowAddProductModal() {
      this.showAddProductModal = true;
      this.newProductForm = deepClone(EMPTY_PRODUCT);
    },
    listProductTypes() {
      listProductTypes().then((res) => {
        if (res.data.success) {
          this.productTypes = res.data.data;
        }
      });
    },
    handleCloseModal() {
      this.showAddProductModal = false;
      this.showEditProductVersionModal = false;
      this.showEditApiAddrModal = false;
    },
    async handleDeleteProduct(row) {
      deleteProductCluster({ id: row.id }).then((res) => {
        if (res.data.success) {
          this.$Message.success(this.$t('shan-chu-cheng-gong'));
          const selectCcProductCluster = localStorage.getItem('selectCcProductCluster');
          if (row.clusterCode === selectCcProductCluster) {
            localStorage.removeItem('selectCcProductCluster');
          }
          window.location.reload();
        }
      });
    },
    handleShowEditProductVersionModal(record) {
      this.selectedProductCluster = deepClone(record);
      this.currentProductVersion = this.selectedProductCluster.productVersion;
      this.showEditProductVersionModal = true;
    },
    handleUpdateProductVersion() {
      updateProductVersion({
        id: this.selectedProductCluster.id,
        productVersion: this.currentProductVersion
      }).then((res) => {
        if (res.data.success) {
          this.showEditProductVersionModal = false;
          this.$Message.success(this.$t('xiu-gai-chan-pin-ji-qun-ban-ben-hao-cheng-gong'));
          window.location.reload();
        }
      });
    },
    handleEditApiAddr(record) {
      this.selectedProductCluster = deepClone(record);
      this.currentApiAddr = this.selectedProductCluster.apiAddr;
      this.showEditApiAddrModal = true;
    },
    handleUpdateApiAddr() {
      updateApiAddr({
        id: this.selectedProductCluster.id,
        apiAddr: this.currentApiAddr
      }).then((res) => {
        if (res.data.success) {
          this.showEditApiAddrModal = false;
          this.$Message.success(this.$t('xiu-gai-chan-pin-ji-qun-fang-wen-di-zhi-cheng-gong'));
          window.location.reload();
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.query-header {
  margin-bottom: 12px;

  .op {
    margin-left: auto;

    & > button + button {
      margin-left: 10px;
    }
  }
}
</style>
