<template>
  <div class="env">
    <div class="table-list-layout">
      <div class="table-list">
        <div class="header">
          <Breadcrumb>
            <BreadcrumbItem>{{ $t('huan-jing-guan-li') }}</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div class="content">
          <div class="option">
            <div class="left">
              <Input v-model="search" style="width: 280px;margin-right: 10px;" clearable :placeholder="$t('qing-shu-ru-huan-jing-ming-cheng-cha-xun')"></Input>
              <Button @click="getEnvList" type="primary">{{ $t('cha-xun') }}</Button>
            </div>
            <div class="right">
              <Button @click="handleShowAddEnv" type="primary" ghost style="margin-right: 10px;" icon="md-add" v-if="myAuth.includes('RDP_ENV_MANAGE')">{{ $t('xin-jian-huan-jing') }}</Button>
              <Button @click="getEnvList">{{ $t('shua-xin') }}</Button>
            </div>
          </div>
          <div class="table">
            <Table :columns="envColumns" :data="showEnvList" size="small" :loading="loading" border>
              <div slot="action" slot-scope="{ row }">
                <Button v-if="myAuth.includes('RDP_ENV_MANAGE')" size="small" type="text" @click="handleEdit(row)">
                  {{ $t('bian-ji') }}
                </Button>
                <Poptip
                  confirm
                  transfer
                  :title="$t('que-ding-shan-chu-gai-huan-jing-ma')"
                  :ok-text="$t('que-ding')"
                  :cancel-text="$t('qu-xiao')"
                  v-if="myAuth.includes('RDP_ENV_MANAGE')"
                  @on-ok="handleDeleteEnv(row)"
                >
                  <Button type="text">{{ $t('shan-chu') }}</Button>
                </Poptip>
              </div>
            </Table>
          </div>
        </div>
      </div>
      <div class="footer">
        <Page :total="total" show-total show-elevator @on-change="handlePageChange"
              show-sizer
              v-model="pageNum"
              :page-size="pageSize" @on-page-size-change="handlePageSizeChange"/>
      </div>
    </div>
    <Modal
      :title="isEdit?$t('bian-ji-huan-jing'):$t('chuang-jian-huan-jing')"
      v-model="showAddEnvModal"
      @on-cancel="handleCloseModal"
    >
      <Form :model="addEnv" ref="addEnv" :rules="rules" :label-width="80">
        <FormItem :label="$t('huan-jing-ming-cheng')" prop="envName">
          <Input v-model="addEnv.envName"/>
        </FormItem>
        <FormItem :label="$t('bei-zhu')">
          <Input v-model="addEnv.description"/>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button type="default" @click="handleCloseModal">{{ $t('qu-xiao') }}</Button>
        <Button type="primary" @click="handleAddEnv">{{ isEdit ? $t('que-ren') : $t('tian-jia') }}</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import deepClone from 'lodash.clonedeep';

const EMPTY_ENV = {
  description: '',
  envName: ''
};

export default {
  name: 'Env',
  computed: {
    ...mapState(['userInfo', 'myCatLog', 'myAuth'])
  },
  data() {
    return {
      showAddEnvModal: false,
      isEdit: false,
      showDeleteEnv: false,
      loading: false,
      search: '',
      selectedEnv: {},
      envList: [],
      showEnvList: [],
      pageSize: 20,
      pageNum: 1,
      total: 0,
      rules: {
        envName: [
          {
            required: true,
            message: this.$t('qing-tian-xie-huan-jing-ming-cheng'),
            trigger: 'blur'
          }
        ]
      },
      addEnv: deepClone(EMPTY_ENV),
      envColumns: [
        {
          title: this.$t('huan-jing-ming-cheng'),
          key: 'envName'
        }, {
          title: this.$t('bei-zhu'),
          key: 'description'
        }, {
          title: this.$t('cao-zuo'),
          fixed: 'right',
          width: 160,
          slot: 'action'
        }],
      envValidate: {
        envName: [
          {
            required: true,
            message: this.$t('yun-hang-huan-jing-ming-cheng-bu-neng-wei-kong'),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  mounted() {
    this.getEnvList();
  },
  methods: {
    handlePageChange(pageNum) {
      this.pageNum = pageNum;
      this.setTableShowData();
    },
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.handlePageChange(1);
    },
    setTableShowData() {
      const { pageNum, pageSize } = this;
      this.showEnvList = this.envList.slice((pageNum - 1) * pageSize, pageNum * pageSize);
    },
    async getEnvList() {
      this.loading = true;
      const res = await this.$services.listDsEnv({
        data: {
          envName: this.search
        }
      });
      this.loading = false;
      if (res.success) {
        this.envList = res.data;
        this.total = res.data.length;
        this.setTableShowData();
      }
    },
    handleShowAddEnv() {
      this.showAddEnvModal = true;
    },
    async addEnvFunc() {
      const data = {
        authKinds: [],
        description: this.addEnv.description,
        envName: this.addEnv.envName,
        queryLimit: this.addEnv.queryLimit
      };
      const res = await this.$services.addDsEnv({ data });
      if (res.success) {
        this.$Message.success(this.$t('tian-jia-cheng-gong'));
        this.handleCloseModal();
        await this.getEnvList();
      }
    },
    async editEnvFunc() {
      const data = {
        authKinds: [],
        description: this.addEnv.description,
        envName: this.addEnv.envName,
        queryLimit: this.addEnv.queryLimit,
        dsEnvId: this.addEnv.id
      };
      const res = await this.$services.updateEnv({ data });
      if (res.success) {
        this.$Message.success(this.$t('bian-ji-cheng-gong'));
        this.handleCloseModal();
        await this.getEnvList();
      }
    },
    handleAddEnv() {
      this.$refs.addEnv.validate((valid) => {
        if (valid) {
          if (this.isEdit) {
            this.editEnvFunc();
          } else {
            this.addEnvFunc();
          }
        }
      });
    },
    async handleDeleteEnv(row) {
      const res = await this.$services.deleteEnv({
        data: {
          dsEnvId: row.id
        }
      });
      if (res.success) {
        this.$Message.success(this.$t('shan-chu-cheng-gong'));
        await this.getEnvList();
      }
    },
    handleCloseModal() {
      this.addEnv = deepClone(EMPTY_ENV);
      this.isEdit = false;
      this.showAddEnvModal = false;
      this.showDeleteEnv = false;
    },
    handleEdit(row) {
      this.addEnv = { ...row };
      this.isEdit = true;
      this.showAddEnvModal = true;
    }
  }
};
</script>

<style lang="less" scoped>
.env {
  height: 100%;
  position: relative;

  .operation {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    .left, .right {
      display: flex;
      align-items: center;
    }

    .left {
      .type-text {
        word-break: keep-all;
        margin-right: 10px;
      }

      .type {
        margin-right: 20px;
      }

      .text {
        margin-right: 10px;
      }
    }

    .right {
      .refresh {
        margin-left: 10px;
      }
    }
  }
}

.add-env-auth-title {
  color: #333333;
  font-family: PingFangSC-Semibold;
  font-weight: 500;
  margin-bottom: 18px;

  .add-env-auth-remark {
    color: @icon-color;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    margin-left: 8px;
  }
}

.add-env-item {
  line-height: 50px;
}

.page-container {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
}
</style>
